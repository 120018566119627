import { Notify } from "vant";
import ajax from "./ajax.js";
import router from "@/router";

let base = process.env.VUE_APP_BASE_URL;

const ownCoursesByTerm = (termId) => {
  return ajax.get(`${base}/api/itedu/v1/user/term/course?termId=${termId}`);
};

export const userCourseApi = {
  // import时得花括号明确
  ownCoursesByTerm: ownCoursesByTerm,
};
